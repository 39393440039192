/* ==========================================================================
   #INTRO
   ========================================================================== */

.intro {
    position: relative;
    width: 100%;

    &__background {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../img/hero-bg-1.jpg');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;

        &:after {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            background: linear-gradient(rgba(235, 240, 248, .2), rgba(235, 240, 248, .2) 60%, #ebf0f8);
            top: 0;
            left: 0;
            z-index: 0
        }

        &--home {
            background-image: url('../img/hero-bg-1.jpg');
        }
        &--aparate-de-stropit {
            background-image: url('../img/hero-bg-aparate-de-stropit.jpg');
        }

        &--aparate-de-muls {
            background-image: url('../img/hero-bg-1.jpg');
        }

        &--motopompe {
            background-image: url('../img/hero-bg-motopompe.jpg');
        }

        &--aparate-de-stropit:before,
        &--aparate-de-muls:before,
        &--motopompe:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            background: linear-gradient(to top, rgba(235, 240, 248, .2), rgba(235, 240, 248, .2) 80%, #ebf0f8);
            top: 0;
            left: 0;
            z-index: 0;
        }
    }

    // @each $slug in mulgatoare,motopompe,stropitoare {
    //     &__background--#{$slug} {
    //         background-image: url('../img/bg-#{$slug}.jpg');
    //         transition: background-image .3s ease-in .6s;
    //     }
    // }

    &__inner {
        position: relative;
        padding-top: rem(100px);
    }

    &--about .intro__inner {
        padding-bottom: $spacing-unit--large;
    }

    &--about .intro__background:after {
        background: linear-gradient(rgba(235, 240, 248, .2), rgba(235, 240, 248, .2) 20%, #ebf0f8);
    }

    &--error {
        height: 80vh;
    }

    &--error .intro__inner {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    &__heading {
        font-family: $amaranth-bold;
        font-size: rem(32px);
        line-height: 1.2;
        color: $c--brand-secondary;

        span {
            color: $c--brand-primary;
        }

    }
    @include mq($from: phone-wide) {

        &__heading {
            font-size: rem(36px);
        }

    }

    @include mq($from: tablet-small) {

        &__inner {
            padding-top: rem(150px);
        }

        &__heading {
            font-size: rem(60px);
            margin-top: $spacing-unit;
        }
    }

    @include mq($to: tablet,  $and: '(orientation: landscape)') {

        &__inner {
            padding-top: rem(60px);
        }

        &__heading {
            font-size: rem(42px);
        }
    }

    @include mq($from: tablet) {

        &__inner {
            padding-top: rem(100px);
        }
    }

    @include mq($from: tablet-wide) {

        &__inner {
            padding-top: rem(180px);
        }
    }

    @include mq($from: desktop) {

        &__inner {
            min-height: 50vh;
        }
        &__inner--terms {
            min-height: 30vh;
        }
        &__inner--terms .intro__heading {
            margin-top: 0px;
        }
    }
}