/* ==========================================================================
   #SECTION
   ========================================================================== */


.section {
    position: relative;
    display: block;
    background-color: $c--grey-theme;
    z-index: 2;

    &--white {
        background-color: $c--white;

    }

    &--rounded-bottom {
        padding-bottom: $spacing-unit--medium;
        border-radius: 50% 50% 50% 50% / 0% 0% 20% 20%;

        @include mq($from: tablet-wide) {
            padding-bottom: $spacing-unit--medium;
        }

    }

    &--rounded-top {
        @include soft--ends($spacing-unit--x-large);
        border-radius: 50% 50% 50% 50% / 20% 20% 0% 0%;
    }

}