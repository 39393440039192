/* ==========================================================================
   #MENU-ICON
   ========================================================================== */

.menu-icon {
    cursor: pointer;
    position: relative;
    float: right;
    width: 3.5rem;
    height: 3.5rem;

    span {
        position: absolute;
        display: block;
        width: 40%;
        height: 2px;
        left: 30%;
        transition: all 0.3s ease 0s;
        background: $c--brand-primary;
    }

    span:first-child {
        transform: rotate(0deg);
        top: 23px;
    }

    span:nth-child(2) {
        transform: rotate(0deg);
        bottom: 23px;
    }

    &.menu-is-open span:first-child {
        transform: rotate(45deg);
        top: 29px;
    }

    &.menu-is-open span:nth-child(2) {
        transform: rotate(-45deg);
        bottom: 25px;
    }
}