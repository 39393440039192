/* ==========================================================================
   #HERO
   ========================================================================== */

.hero {
    margin-top: 10rem;
    padding-bottom: 2rem;
    background-color: transparent;

    @include mq($from: tablet-small) {
        margin-top: 10rem;
    }

    @include mq($to: tablet,  $and: '(orientation: landscape)')  {
        margin-top: rem(60px);
    }

    &--single-product {
        margin-top: $spacing-unit;
    }

    &--contact {
        margin-top: 5rem;
    }

    &--terms {
        margin-top: $spacing-unit;
    }
}

.hero-box {
    text-align: center;
    display: block;

    &__image-placeholder {
        margin-bottom: $spacing-unit--small;
    }

    &__label {
        color: $c--brand-secondary;
        letter-spacing: 0.03em;
        margin-top: 0px;
    }
}


.hero__lead {
    width: 100%;
    margin: 0 auto;
    font-family: $opensans-regular;
    font-size: 16px;

    @include mq($from: tablet) {
        width: 80%;
    }

    >span {
        font-family: $opensans-bold;
        color: $c--brand-secondary;
    }
}