/*
	Box sizing
*/

* {

	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}
}

/*
	Basic document styling
*/

html {
	font-size: $html-font-size;
	min-height: 100%;
	overflow-y: scroll;
	-webkit-text-size-adjust: none;
}

body {
	@include antialias;
	// background-color: $c--body-bg;
	background-color: $c--white;
	color: $c--body-colour;
	font-family: $stack;
	font-size: $p;
	line-height: $lh;
	min-width: rem(320px);
}

.site-wrapper {
	overflow: hidden;
}


/*

	Text selection/highlighting

*/

@include selection {
	background: $c--brand-primary;
	color: $c--white;
	text-shadow: none;
}


.error-message p {
	font-family: $amaranth-regular;
	font-size: rem(18px);
	color: $c--black;
}

/*
	Vue
*/

[v-cloak] {
	display: none;
}