@import 'settings';
@import 'utility/functions';
@import '../variables.json';


/*
	Typography
*/

// font stacks
// for embedded custom fonts, list name below as normal. To generate code see example in utlity/core.scss
$stack: 'Open Sans', Helvetica, Arial, sans-serif;
$stack--heading: 'Amaranth-Bold', Helvetica, Arial, sans-serif;

$amaranth-regular: 'Amaranth-Regular', serif;
$amaranth-italic: 'Amaranth-Italic', serif;
$amaranth-bold: 'Amaranth-Bold', serif;
$amaranth-bold-italic: 'Amaranth-BoldItalic', serif;

$opensans-light: 'OpenSans-Light', sans-serif;
$opensans-regular: 'OpenSans-Regular', sans-serif;
$opensans-bold: 'OpenSans-Bold', sans-serif;


// font sizes
$h--1: (base: rem(30px), tablet: rem(36px), desktop: rem(60px),);
$h--2: (base: rem(26px), tablet: rem(30px), desktop: rem(48px),);
$h--3: (base: rem(22px), tablet: rem(26px), desktop: rem(38px),);
$h--4: (base: rem(20px), tablet: rem(22px), desktop: rem(30px),);
$h--5: (base: rem(18px), tablet: rem(20px),desktop: rem(24px),);
$h--6: (base: rem(16px), tablet: rem(18px),desktop: rem(20px),);

$p: rem(16px);
$p--small: rem(12px);
$p--medium: rem(18px);
$p--large: rem(24px);

// line-height ratios
$lh: 1.5;
$lh--x-small: 1.1;
$lh--small: 1.25;
$lh--large: 1.75;

// letter spacing
$ls: ( // base: -30
);

// weights
$w--light: 300;
$w--normal: 400;
$w--semibold: 600;
$w--bold: 700;
$w--heavy: 800;


/*
	Layout
*/

// basic spacing units
$spacing-unit: rem(20px);
$spacing-unit--x-small: rem(10px);
$spacing-unit--small: rem(15px);
$spacing-unit--medium: rem(30px);
$spacing-unit--large: rem(40px);
$spacing-unit--x-large: rem(60px);
$rel-spacing-unit: 1em;
$rel-spacing-unit--small: .5em;

// page gutter
$page-gutter: $spacing-unit--x-small;
$page-gutter--tablet: $spacing-unit--medium;
$page-gutter--desktop: $spacing-unit--large;

// grid
$grid-column-count: 24;
$grid-column-gutter: $spacing-unit;
$grid-column-gutter--tablet: $spacing-unit--medium;
$grid-column-gutter--desktop: $spacing-unit--large;

// media queries and breakpoints
// build mobile first 300+
$breakpoints--rem: $breakpoints;

@each $name,
$width in $breakpoints {
	$breakpoints--rem: map-merge($breakpoints--rem, ($name: rem($width * 1px)));
}

@each $name,
$width in $breakpoints {
	$breakpoints: map-merge($breakpoints, ($name: em($width * 1px)));
}

// once dev mode is false, you must define any block grids, column grids and flush margins for each breakpoint
// this is to keep unused code to a minimum
$grid-bp: ('base': ('width': (),
'push': (),
'pull': (),
'block': (),
'flush': ()),
'phone': ('width': (),
'push': (),
'pull': (),
'block': (),
'flush': ()),
'phone-wide': ('width': (),
'push': (),
'pull': (),
'block': (),
'flush': ()),
'phablet': ('width': (),
'push': (),
'pull': (),
'block': (),
'flush': ()),
'tablet-small': ('width': (),
'push': (),
'pull': (),
'block': (),
'flush': ()),
'tablet': ('width': (),
'push': (),
'pull': (),
'block': (),
'flush': ()),
'tablet-wide': ('width': (),
'push': (),
'pull': (),
'block': (),
'flush': ()),
'desktop': ('width': (),
'push': (),
'pull': (),
'block': (),
'flush': ()),
'desktop-wide': ('width': (),
'push': (),
'pull': (),
'block': (),
'flush': ()));

$max-width: breakpoint('desktop', true);

// z-indexing
// ordered from high to low
// suggested naming convention would be the class/ID the z-index is going on
$z-indexes: ('outdated-browser',
'site-header',
'site-content',
'site-footer'
);


/*
	Colours
*/

// brand colours
$c--brand-primary: #087a04;
$c--brand-secondary: #3d4c3d;
$c--brand-tertiary: #d92726;

// greyscale
$c--black: #1a1a1a;
$c--grey-dark: #444;
$c--grey: #888;
$c--grey-theme: #ebf0f8;
$c--grey-text: #8d8d8d;
$c--grey-light: #ddd;
$c--grey-x-light: #fafafa;
$c--white: #fff;

// other colours
$c--red: #f50023;
$c--green: #24b35d;
$c--blue: #4D90FE;

// social colours
$c--twitter: #55acee;
$c--facebook: #3b5998;
$c--youtube: #bb0000;
$c--pinterest: #cb2027;
$c--linkedin: #007bb5;
$c--instagram: #8a3ab9;

// form & message positive/negative colours
$c--positive: $c--green;
$c--negative: $c--red;

// top level element colours
$c--body-bg: $c--grey-theme;
$c--body-colour: $c--grey-text;

// colour map (only use colours from the styleguide)
// used for when looping through panel/theme styles
$colours: ( // primary colours
'yellow': ('base': $c--brand-primary),
'green': ('base': $c--green),
'red': ('base': $c--red),
'blue': ('base': $c--blue),
// greyscale
'black': ('base': $c--black),
'grey': ('base': $c--grey),
'grey-dark': ('base': $c--grey-dark),
'grey-light': ('base': $c--grey-light),
'grey-off-white': ('base': $c--grey-x-light),
'white': ('base': $c--white));

$global-transition: all 0.3s;
$global-radius: 3px;

/*
	Easing/Timing
*/

// Cubic
$ease--in-cubic: cubic-bezier(.55, .055, .675, .19);
$ease--out-cubic: cubic-bezier(.215, .61, .355, 1);
$ease--in-out-cubic: cubic-bezier(.645, .045, .355, 1);

// Circ
$ease--in-circ: cubic-bezier(.6, .04, .98, .335);
$ease--out-circ: cubic-bezier(.075, .82, .165, 1);
$ease--in-out-circ: cubic-bezier(.785, .135, .15, .86);

// Expo
$ease--in-expo: cubic-bezier(.95, .05, .795, .035);
$ease--out-expo: cubic-bezier(.19, 1, .22, 1);
$ease--in-out-expo: cubic-bezier(1, 0, 0, 1);

// Quad
$ease--in-quad: cubic-bezier(.55, .085, .68, .53);
$ease--out-quad: cubic-bezier(.25, .46, .450, .94);
$ease--in-out-quad: cubic-bezier(.455, .03, .515, .955);

// Quart
$ease--in-quart: cubic-bezier(.895, .03, .685, .22);
$ease--out-quart: cubic-bezier(.165, .84, .44, 1);
$ease--in-out-quart: cubic-bezier(.77, 0, .175, 1);

// Quint
$ease--in-quint: cubic-bezier(.755, .05, .855, .06);
$ease--out-quint: cubic-bezier(.23, 1, .320, 1);
$ease--in-out-quint: cubic-bezier(.86, 0, .07, 1);

// Sine
$ease--in-sine: cubic-bezier(.47, 0, .745, .715);
$ease--out-sine: cubic-bezier(.39, .575, .565, 1);
$ease--in-out-sine: cubic-bezier(.445, .05, .55, .95);

// Back
$ease--in-back: cubic-bezier(.6, -.28, .735, .045);
$ease--out-back: cubic-bezier(.175, .885, .32, 1.275);
$ease--in-out-back: cubic-bezier(.68, -0.55, .265, 1.55);

// Smooth fade
$ease--smooth-fade: cubic-bezier(.365, .005, .355, 1);