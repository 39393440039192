/*---

```
<div class="table-wrapper">
	<table>
		<caption>A useful description of what is in this table</caption>

		<thead>
			<th scope="col">Nulla pariatur</th>

			<th scope="col">Perspiciatis</th>

			<th scope="col">Natus error</th>

			<th scope="col">Inventore</th>

			<th scope="col">Accusantium</th>
		</thead>

		<tbody>
			<tr>
				<td scope="row">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo quod distinctio aperiam magnam dicta nesciunt et amet quia necessitatibus cupiditate voluptates libero, ea vero aliquid neque perspiciatis mollitia, impedit possimus.</td>

				<td>Tempore ipsa consequuntur ipsum cum voluptatibus, corrupti alias. Laudantium deserunt magnam reiciendis labore esse eius voluptas atque, officia, numquam, eaque voluptatibus aliquid.</td>

				<td>Maiores enim porro voluptatibus illum nobis id ea consequuntur, officiis quod voluptates adipisci dolorem iure aut, maxime rerum sed temporibus fugiat! Ad.</td>

				<td>Soluta vel ad recusandae vero nesciunt velit ipsa odit nihil, consequatur expedita officiis? Aspernatur hic vero veritatis unde voluptatum, adipisci recusandae laborum?</td>

				<td>Qui mollitia delectus iste veniam, quos sequi sint numquam, ipsa suscipit dicta illum et saepe architecto ipsam ab quaerat eum, impedit doloribus.</td>
			</tr>

			<tr>
				<td scope="row">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo quod distinctio aperiam magnam dicta nesciunt et amet quia necessitatibus cupiditate voluptates libero, ea vero aliquid neque perspiciatis mollitia, impedit possimus.</td>

				<td>Tempore ipsa consequuntur ipsum cum voluptatibus, corrupti alias. Laudantium deserunt magnam reiciendis labore esse eius voluptas atque, officia, numquam, eaque voluptatibus aliquid.</td>

				<td>Maiores enim porro voluptatibus illum nobis id ea consequuntur, officiis quod voluptates adipisci dolorem iure aut, maxime rerum sed temporibus fugiat! Ad.</td>

				<td>Soluta vel ad recusandae vero nesciunt velit ipsa odit nihil, consequatur expedita officiis? Aspernatur hic vero veritatis unde voluptatum, adipisci recusandae laborum?</td>

				<td>Qui mollitia delectus iste veniam, quos sequi sint numquam, ipsa suscipit dicta illum et saepe architecto ipsam ab quaerat eum, impedit doloribus.</td>
			</tr>

			<tr>
				<td scope="row">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo quod distinctio aperiam magnam dicta nesciunt et amet quia necessitatibus cupiditate voluptates libero, ea vero aliquid neque perspiciatis mollitia, impedit possimus.</td>

				<td>Tempore ipsa consequuntur ipsum cum voluptatibus, corrupti alias. Laudantium deserunt magnam reiciendis labore esse eius voluptas atque, officia, numquam, eaque voluptatibus aliquid.</td>

				<td>Maiores enim porro voluptatibus illum nobis id ea consequuntur, officiis quod voluptates adipisci dolorem iure aut, maxime rerum sed temporibus fugiat! Ad.</td>

				<td>Soluta vel ad recusandae vero nesciunt velit ipsa odit nihil, consequatur expedita officiis? Aspernatur hic vero veritatis unde voluptatum, adipisci recusandae laborum?</td>

				<td>Qui mollitia delectus iste veniam, quos sequi sint numquam, ipsa suscipit dicta illum et saepe architecto ipsam ab quaerat eum, impedit doloribus.</td>
			</tr>
		</tbody>
	</table>
</div>
```

*/

.table-wrapper {
	max-width: 100%;
	overflow-x: auto;
}

table {
	width: 100%;
	border: 1px solid $c--grey-light;
	border-top: none;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: $p;
	line-height: $lh--small;

	caption {
		@include vh;
	}
}

th,
td {
	@include soft--ends($spacing-unit--small);
	padding-right: $spacing-unit--small;
	border-top: 1px solid $c--grey-light;
	vertical-align: top;

	&:first-child {
		padding-left: $spacing-unit--small;
	}
}

th {
	font-weight: $w--bold;
	text-align: left;
}


.table-wrapper {
	@include mq($from: desktop) {
		max-width: 80%;
		margin-right: auto;
		margin-left: auto;
	}
}

.table-legend p {
	font-size: rem(13px);
	color: $c--grey-text;
	margin-top: 6px;
	margin-left: 6px;
}

.table>thead>tr>th {
    background: #3d4c3d;
    color: $c--white;
    max-width: 200px;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>thead>tr>td, .table>thead>tr>th {
	@include mq($from: tablet) {
		border: 0;
	    padding: 16px 24px;
	    text-align: left;
	    border-right: 1px solid #c2c2c2;
	    line-height: 1.4;
	    font-size: 16px;
	}
}

.table>thead>tr>th:last-child,
.table>thead>tr>td:last-child,
.table>tbody>tr>th:last-child,
.table>tbody>tr>td:last-child  {
	@include mq($from: tablet) {
		border-right: 0;
	}
}


.table>thead>tr {
	position: absolute;
	top: -9999px;
	left: -9999px;

	@include mq($from: tablet) {
		position: relative;
		top: 0;
		left: 0;
	}
}

.table>tbody>tr>th {
	@include mq($to: tablet) {
		display: block;
		background: #3d4c3d;
		color: $c--white;
	}
}

.table>tbody>tr>th,
.table>tbody>tr>td {
	@include mq($to: tablet) {
		font-size: 13px;
	    border: 0;
	    text-align: left;
	    display: block;
	}
}

.table>tbody>tr>td {

	@include mq($to: tablet) {
		border: none;
	    position: relative;
	    padding-left: calc(50% + 16px);
	}
}


.table>tbody>tr>td:nth-of-type(even) {
	@include mq($to: tablet) {
		background: #e5e6e6;
	}
}

.table>tbody>tr>td:nth-of-type(odd){
	@include mq($to: tablet) {
		 background: $c--white;
	}
}

.cell-title {
	box-sizing: content-box;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: calc(50% - 33px);
    top: 50%;
    transform: translateY(-50%);
    padding: 8px 16px;
    font-family: $opensans-regular;
    font-size: rem(13px);
    color: #333;
    // text-transform: uppercase;
    border-right: 1px solid #c2c2c2;

    @include mq($from: tablet) {
    	display: none;
    }
}

.table>tbody>tr:nth-of-type(even) {
    background: #e5e6e6;
}


.table>tbody>tr>td, .table>tbody>tr>th {
	@include mq($from: tablet) {
		border: 0;
	    padding: 16px 24px;
	    text-align: left;
	    border-right: 1px solid #c2c2c2;
	    line-height: 1.4;
	    font-size: 14px;
	}
}

.details-table > tbody > tr> td {
	background: $c--grey-x-light;
	color: #1a1a1a;
	padding-left: 10px;
	font-family: $opensans-regular;
}

.details-table > tbody > tr> th {
	color: #3d4c3d;
	font-family: $amaranth-bold;
	font-size: rem(18px);
}