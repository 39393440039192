.grid {
    @mixin macro($spacing) {
        margin-left: n($spacing);
        &__item {
            margin-top: $spacing;
            padding-left: $spacing;
        }
    }
    @include macro($grid-column-gutter);
    display: flex;
    flex-wrap: wrap;
    margin-left: n($grid-column-gutter);

    &__item {
        flex-shrink: 0;
        position: relative;
        width: 100%;
    }
    &--reversed {
        flex-direction: column;
        @include mq($from: tablet) {
            flex-direction: row-reverse;
        }
    }
    &--centered {
        align-items: center;
    }

    @include mq(tablet) {
        @include macro($grid-column-gutter--tablet);
    }
    @include mq(desktop) {
        @include macro($grid-column-gutter--desktop);
    }
}