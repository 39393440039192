/* ==========================================================================
   # MODULE: CONTAINER
   ========================================================================== */


.container {
	@include push--sides($page-gutter);
	position: relative;
	max-width: $max-width;

	@include mq(tablet) {
		@include push--sides($page-gutter--tablet);
	}

	@include mq(desktop) {
		@include push--sides($page-gutter--desktop);
	}

	@include mq(em($max-width + (2 * $page-gutter--desktop))) {
		@include push--auto;
	}

	&--full-width {
		max-width: none;
		@include push--auto;
	}
}