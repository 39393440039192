/* ==========================================================================
   #CTA
   ========================================================================== */


.cta {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    h2 {
        color: $c--brand-primary;
        font-family: $amaranth-bold;
    }

    >* {
        @include fp(margin-bottom, 16, 32);
    }

    @include mq($from: tablet) {
        width: 80%;
    }

    @include mq($from: tablet-wide) {
        width: 50%;
    }
}

.cta-box {
    display: block;
    position: relative;
    border-radius: 40px;
    height: 100%;

    @include mq($from: tablet) {
        border-radius: 60px 60px 0px 0px;
    }

    &--green {
        background-color: $c--brand-primary;
        color: $c--white;

        @include mq($to: tablet) {
            margin-bottom: $spacing-unit;
        }
    }

    &--gray {
        background-color: $c--grey-theme;
        color: $c--black;

        .cta-box__heading {
            color: $c--brand-primary;
        }
    }

    &__inner {
        height: 100%;
        width: 100%;
        padding: $spacing-unit--medium;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        position: relative;
        z-index: 1;

        >* {
            margin-bottom: $spacing-unit;
        }

        @include mq($from: tablet-wide) {
            padding: $spacing-unit--large;
        }

        p {
            font-size: rem(18px);
            line-height: 1.5;
        }
        .button-wrapper {
            align-self: baseline;
        }
    }

    &__heading {
        text-transform: uppercase;
        @include fp(font-size, 32, 48);
    }

    &__icon {
        position: absolute;
        height: 50%;
        top: 50%;
        transform: translateY(-50%);
        right: 5%;
        max-width: none;
        z-index: 0;
        opacity: 0.7;

        @include mq($from: tablet-wide) {
            height: 80%;
        }
    }

}