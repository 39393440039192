/* ==========================================================================
   # MODULE: BANNER BLOCK
   ========================================================================== */

   .banner-block {

        position: relative;
        display: block;
        text-align: center;

        &__align {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 80%;
            @include push--auto();
        }

        &__copy h4 {
            color: $c--white;
            line-height: 1.2;
        }
        &__copy p {
            color: $c--white;
            font-family: $opensans-light;
            font-size: rem(14px);
            margin-top: $spacing-unit--x-small;
            margin-bottom: $spacing-unit;

            @include mq($from: tablet) {
                font-size: rem(18px);
            }
        }

        img {
            border-radius: 30px 30px 30px 0px;
        }

   }