/* ==========================================================================
   # MODULE: CONTACT FORM
   ========================================================================== */

.contact-wrapper {
	position: relative;
	display: block;
	padding: $spacing-unit $spacing-unit--small;
	border-radius: 10px;
    background-image: url('../img/BackgroundGreen.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include mq($from: tablet) {
    	padding: $spacing-unit--large $spacing-unit--medium;
    }
}

.contact-right {
	@include mq($from: tablet) {
		margin-top: 60px;
	}
}

.contact-align {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.contact-social {
	margin-bottom: $spacing-unit--medium;
}

.contact-info {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: $spacing-unit--medium;

	p {
		color: $c--white;
		font-family: $amaranth-bold;
		font-size: rem(16px);
		min-width: 60px;
		letter-spacing: 0.02em;

		@include mq($from: tablet-small) {
			font-size: rem(22px);
		}
	}

	&__label {
		font-family: $amaranth-regular;
		font-size: rem(20px);
		min-width: 60px;
		padding-right: $spacing-unit--small;
		font-weight: 300;
	}
	a {
		color: $c--white;
		font-family: $amaranth-regular;

		&:hover,
		&:focus {
			color: $c--brand-tertiary;
		}
	}
}

.contact-image {
	max-width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.contact-form {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: $spacing-unit;


	&__wrapper {
		// padding-top: $spacing-unit--large;
	}

	&__copy {
		text-align: left;
		margin-bottom: $spacing-unit--medium;
		color: $c--white;
	}
	&__block {
		width: 100%;
		margin-bottom: $spacing-unit--medium;	

		p {
			font-size: rem(13px);
			opacity: 0.8;
			font-style: italic;
		}
	}

	&__group {
		position: relative;
		margin-bottom: $spacing-unit;

	}

	&__label {
		font-size: rem(16px);
		line-height: rem(20px);
		color: $c--black;
		font-family: $amaranth-regular;
		font-weight: 700;
		margin-bottom: $spacing-unit--x-small;
		position: relative;
		display: block;
	}
		
	&__input {
	    color: $c--brand-primary;
	    background-color: #fafafa;
	    display: block;
	    font-family: $amaranth-regular;
	    padding: 1rem 0.8rem;
	    height: 60px;
	    width: 100%;
	    font: normal 18px/1.5 sans-serif;
	    @include border-radius(5px 0px 5px 5px);
	    border: 1px solid lighten($c--brand-primary, 5%);
		
		@include input-placeholder {
			color: $c--grey-light
		}
	    &:focus {
	    	box-shadow: 0px 6px 17px -4px rgba($color: $c--white, $alpha: 0.4);
	    }
	}

	&__textarea {
		
		width: 100%;
		background-color: #fafafa;
		padding: 1rem 0.8rem;
		@include border-radius(5px 0px 5px 5px);

		&:focus {
	    	box-shadow: 0px 6px 17px -4px rgba($color: $c--white, $alpha: 0.4);
	    }
	}

	&__error {
		color: $c--brand-tertiary;
	}

}

.parsley-custom-error-message {
	font-size: rem(12px);
	color: $c--white;
}