/* ==========================================================================
   #ABOUT PAGE
   ========================================================================== */


   .about-block {
   		height: 100%;

   		&__copy {
   			height: inherit;
   			display: flex;
   			flex-direction: column;
   			justify-content: flex-start;
   			align-items: flex-start;
   		}
   		&__copy p {
   			text-align: left;
   			color: $c--black;
   			font-size: rem(18px);
   			line-height: 1.6;
            margin-top: $spacing-unit--large;
   		}
   		&__social {
   			display: flex;

   			li {
   				margin-right: $spacing-unit--small;
   			}
   		}
   }