/* ==========================================================================
   #SERVICE-LIST
   ========================================================================== */

.service-list {
    li {
        @include soft--sides($spacing-unit--small);
        @include push--ends($spacing-unit--small);
    }
}

.model-wrapper {
    display: flex;
    margin-top: $spacing-unit--small;

    div:first-child {
        margin-right: $spacing-unit--small;
        flex-shrink: 0;
        min-width: 150px;
    }
}

.model-list {
    display: flex;
    flex-wrap: wrap;

    &__item {
        margin-right: $spacing-unit--x-small;
        flex-shrink: 0;
    }

    &__link {
        color: $c--brand-tertiary;
        text-decoration: underline;
    }
}