/* ==========================================================================
   #TERMS PAGE
   ========================================================================== */

   .terms-wrapper {
        position: relative;
        display: block;
        padding: 15px 15px 10px 15px;
        border-radius: 10px;
        background-color: $c--grey-x-light;

        @include mq($from: tablet-wide) {
            padding: 50px;
        }
   }

   .terms-article {

        &__header {
            border-bottom: 1px solid $c--grey-light;
            padding-bottom: $spacing-unit--small;

        }

        &__heading {
            font-family: $amaranth-bold;
            color: $c--brand-secondary;
        }

        &__text-block {
            color: $c--grey-dark;
            line-height: 1.4;
        }

        &__text-block p {
            margin-top: 0.5em;
        }
   }