/* ==========================================================================
   #SITE-HEADER/SITE-NAV
   ========================================================================== */

.site-header {
	z-index: z(site-header);
	height: auto;
	max-height: none;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;

	&__placeholder {
		position: relative;
		top: 0;
		left: 50%;
		width: 100vw;
		margin-left: -50vw;
		max-width: 100%;
		z-index: 1;
		height: rem(56px);

		@include mq($from: tablet) {
			position: absolute;
			height: rem(90px);
		}

	}

	&__fixed {
		position: fixed;
		top: 0px;
		right: 0px;
		left: 0px;
		width: 100%;
		transition: none;
		will-change: transform;
		background-color: $c--white;

		@include mq($from: tablet) {
			position: absolute;
			background-color: transparent;
		}

		.menu-is-open & {
			height: 100%;
			overflow: hidden;
			background-color: $c--white;
		}
	}

	&__container {
		position: relative;
		line-height: 1;

		@include mq($to: tablet) {
			height: 56px;
		}
	}
}

.site-nav {
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	z-index: 99;
	// overflow-y: hidden;
	// transition: max-height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
	overscroll-behavior: contain contain;

	&.menu-is-open {
		max-height: 100%;
	}

	@include mq($to: tablet) {
		max-height: 56px;
	}

	@include mq($from: tablet) {
		max-height: rem(90px);
	}

	&__toggle {
		position: absolute;
		z-index: 999;
		top: 0px;
		right: 0px;
		padding: 0px;
		margin: 0px;
		transition: transform 0.3s ease 0;

		@include mq($from: tablet) {
			display: none;
		}
	}

	&__links {
		width: 100%;
		padding-top: 6rem;
		padding-left: $spacing-unit;
		margin-top: 0px;

		@include mq($from: tablet) {
			display: flex;
			align-items: center;
			margin-top: 1rem;
			padding-top: 0px;
			padding-left: 0px;
			padding-right: 0px;
		}

		@include mq($from: tablet-wide) {
			max-height: rem(90px);
		}

	}

	&__item {
		transform: none;
		transition: opacity 0.56s cubic-bezier(0.52, 0.16, 0.24, 1) 0s, transform 0.56s cubic-bezier(0.52, 0.16, 0.24, 1) 0s;
		opacity: 0;
		margin-bottom: $spacing-unit;

		a {
			font-family: $amaranth-regular;
			font-size: rem(24px);
			font-weight: 600;
			opacity: 1;
			color: $c--brand-secondary;

			&:hover,
			&:focus {
				@include mq($from: tablet) {
					color: $c--brand-tertiary;
				}
			}
		}

		> .is-active {
			color: $c--brand-tertiary;
		}

		@include mq($from: tablet) {
			display: inline-block;
			margin: 0px 0px 0px 2.25em;
			opacity: 1;
			transition: none 0s ease 0s;

			a {
				font-size: rem(16px);
				opacity: 1;
				font-weight: 400;
			}
		}

		@include mq($from: desktop) {
			margin: 0px 0px 0px 3.25em;

			a {
				font-size: rem(24px);
			}
		}

		.menu-is-open & {
			opacity: 1;
		}

		&--lang a {
			position: relative;
			display: inline-block;
			padding-right: $spacing-unit--small;
			padding-left: $spacing-unit--small;
		}

		&--lang a:first-child {
			margin-left: -$spacing-unit--small;
		}

		&--lang a:first-child:after {
			content: "";
			position: absolute;
			display: block;
			height: 80%;
			width: 2px;
			background-color: $c--brand-secondary;
			top: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__item:first-child {
		transform: none;
		opacity: 1 !important;
		position: absolute;
		top: 8px;
		left: 0;
		margin-bottom: 0px;

		@include mq($from: tablet) {
			position: relative;
			top: auto;
			left: auto;
			margin-left: 0px;
			margin-right: auto;
		}
	}

	&__item:first-child a {
		opacity: 1 !important;
	}

	&__home {
		display: block;
	}

	&__logo {
		width: rem(100px);
		height: rem(40px);

		@include mq($from: tablet-wide) {
			display: block;
			position: relative;
			width: rem(190px);
			height: rem(74px);
		}
	}
}

.site-nav__item.has-dropdown {
	position: relative;
}

.dropdown {
	overflow: hidden;
	height: 0px;
	visibility: hidden;
	opacity: 0;
	transition: transform 180ms ease 0s, opacity 180ms ease 0s, visibility 180ms ease 0s, height 300ms ease-in-out 0s;

	@include mq($from: tablet) {
		transform-origin: center -10px;
		visibility: hidden;
		opacity: 0;
		box-shadow: rgba(8, 35, 51, 0.05) 0px 2px 4px;
		transform: scale(0.5);
		width: 244px;
		position: absolute;
		top: 50px;
		left: calc(50% - 122px);
		background: rgba(9,139,4,0.7);
		border-radius: 8px;
		padding: 12px 24px;
		height: auto;
		overflow: initial;
	}

	&:before {
		@include mq($from: tablet) {
			content: " ";
		    height: 20px;
		    width: 100%;
		    position: absolute;
		    top: -20px;
		    left: 0px;
		    background: url("../img/caret-dark.svg") center bottom no-repeat;
		}
	}

	&__inner {

		@include mq($to: tablet) {
			display: none;
			padding-top: 12px;
			padding-left: 18px;
		}
	}

	&__link {
	    line-height: 1;
	    width: 100%;
	    white-space: nowrap;
	    display: flex;
	    -webkit-box-pack: justify;
	    justify-content: space-between;
	    align-items: center;
	    transition: transform 100ms ease 0s, color 100ms ease 0s;
	    padding: 12px 0px;
	    font-size: rem(18px) !important;


	    &:hover {

	    	@include mq($from: tablet) {
	    		color: $c--brand-tertiary !important;
	    	}
	    }

	    @include mq($from: tablet) {
	    	color: $c--white !important;
	    	border-bottom: 1px solid $c--grey-x-light;
	    }

	    &.is-active {
	    	color: $c--brand-tertiary !important;
	    }
	}

	&__link:last-child {
		border-bottom: 0px;
	}

	&__trigger {
		@include mq($from: tablet) {
			display: none;
		}
		&.is-branded {
			color: $c--brand-tertiary;
		}
	}

	&__plus {
		display: inline-block;
		margin-left: 10px;

		&.is-hidden {
			display: none;
		}
	}

	&__minus {
		display: none;
		margin-left: 10px;

		&.is-visible {
			@include mq($to: tablet) {
				display: inline-block;
				color: $c--brand-tertiary;

				svg {
					fill: currentColor;
				}
			}
		}
	}

	&.is-visible {
		height: auto;
		opacity: 1;
		visibility: visible;
	}

	&.is-visible .dropdown__inner {
		display: block;
	}

	&.is-visible .dropdown__link {
		color: lighten($c--brand-secondary, 7%);
	}

}

.site-nav__item.has-dropdown:hover > .dropdown  {
	transform: scale(1);
	visibility: visible;
	opacity: 1;
}

.hide-for-mobile {
	@include mq($to: tablet) {
		display: none;
	}
}