/* ==========================================================================
   #SITE-FOOTER
   ========================================================================== */

.site-footer {
    position: relative;
    z-index: z(site-footer);
    background-color: $c--brand-primary;

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq($from: tablet-wide) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__section {
        margin-top: $spacing-unit--small;

        @include mq($to: tablet-small) {
            margin-bottom: $spacing-unit;
        }
    }

    &__section:nth-child(2) {

        @include mq($to: tablet,  $and: '(orientation: landscape)')  {
           order: 1;
        }
        @include mq($to: tablet-small) {
            order: 1;
        }
    }

    &__home {
        display: block;
    }

    &__logo {
        width: rem(100px);
        height: rem(40px);

        @include mq($from: tablet-wide) {
            display: block;
            position: relative;
            width: rem(190px);
            height: rem(74px);
        }
    }

    &__links {
        display: flex;
        flex-direction: column;

        @include mq($to: tablet,  $and: '(orientation: landscape)')  {
            flex-direction: row;
        }

        @include mq($from: tablet) {
            flex-direction: row;
        }

        >li {
            margin-left: $spacing-unit--small;

            @include mq($to: tablet-small) {
                margin-bottom: $spacing-unit--small;
            }
        }

        a {
            color: $c--white;

            &:hover,
            &:focus {
                text-decoration: none;
                color: $c--brand-tertiary;
            }
        }

        &__lang {
            margin-right: $spacing-unit--x-small;
        }
    }

    &__legal {
        color: rgb(148, 206, 142);
        font-size: rem(12px);

        @include mq($from: tablet-small) {
            font-size: rem(16px);
        }
    }
}