/* ==========================================================================
   #SINGLE-PRODUCT
   ========================================================================== */

    .single-product {

        &__lead {
            font-family: $amaranth-regular;
            font-size: rem(20px);
            font-weight: $w--light;
            line-height: $lh--large;
            color: $c--brand-secondary;

            span {
                font-weight: 700;
                color: $c--brand-primary;
            }

        }

        &__heading {
            font-family: $amaranth-bold;
            color: $c--brand-primary;
            letter-spacing: 0.02em;
            line-height: 1.3;
        }

        &__features {
            @include soft--ends($spacing-unit--medium);
        }


        &__copy {
            @include push--ends($spacing-unit);
        }

        &__list {
            list-style: disc;
            padding-left: $spacing-unit--small;
        }

        &__list--iconed {
            list-style: none;

            li {
                display: flex;
                margin-top: $spacing-unit--small;
            }

            li p {
                margin-top:0px;
            }

            li div:first-child  {
                flex-shrink: 0;
            }

            li div:nth-child(2) > p  {
                margin-top: 0px;
            }

            span {
                margin-right: $spacing-unit--small;
                color: $c--brand-primary;
            }
            svg  {
                fill: currentColor;
            }
        }

        &__copy,
        &__list li p {
            font-size: rem(16px);
            color: $c--black;
            line-height: 1.4;
            font-family: $opensans-regular;
            margin-bottom: $spacing-unit--x-small;

            @include mq($from: tablet) {
                font-size: rem(18px);
                 line-height: 1.3;
            }
        }

        &__images {
            overflow: hidden;
            @include push--ends($spacing-unit);

            @include mq($from: tablet) {
                 @include push--ends($spacing-unit--x-large);
            }
        }

        &__thumb {
            float: left;
            @include push--auto();
            width: calc(50% - (2.25rem /2 ));

            img {
                border-radius: 30px;
            }

            &--centered {
                float: none;
            }

            &--centered img {
                border-radius: 0px;
            }
        }

        &__thumb--right {
            margin-top: 10%;
            margin-left: 2.25rem;
        }
    }

    .single-product__images--even .single-product__thumb--right {
        margin-top: 0%;
    }
    .single-product__images--even .single-product__thumb--left {
        margin-top: 10%;
    }

