    /* ==========================================================================
   #SLIDER
   ========================================================================== */

.slider {
    position: relative;
    display: block;


    &__wrapper {
        display: block;
        position: relative;
        overflow: hidden;
        z-index: 2;
        padding: $spacing-unit--small;
    }

    &:after {
        position: absolute;
        content: '';
        background: $c--grey-theme;
        height: 75%;
        width: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        background-image: url('../img/BackgroundGreen.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        -webkit-box-shadow: -1px 1px 12px 2px rgba(61,76,61,1);
        -moz-box-shadow: -1px 1px 12px 2px rgba(61,76,61,1);
        box-shadow: -1px 1px 12px 2px rgba(61,76,61,1);

    }
}

.slide {

    &__inner {
        position: relative;
        text-align: center;
        margin-bottom: $spacing-unit;
        padding: soft--sides($spacing-unit--x-small);
    }

    &__align {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__copy {
        padding: $spacing-unit--x-small;
    }

    &__copy a {
        display: block;
        font-family: $amaranth-regular;
        line-height: 1.3em;
        color: $c--white;
        text-transform: uppercase;
        @include fp(font-size, 16, 20);
    }

    &__copy h1 {
        font-family: $amaranth-bold;
        font-size: rem(32px);
        color: $c--white;
        text-transform: uppercase;
        text-align: center;
    }

    &__image {
        display: block;
        box-shadow: 11px 10px 20px -8px rgba(0, 0, 0, 0.75);
    }

    @include mq($from: tablet-small) {

        &__copy {
            padding: $spacing-unit--small;
        }
    }
}

.slider .glide__slide {
    padding: $spacing-unit--x-small;
}

.slider .glide__track {
    @include offset--sides($spacing-unit--x-small);
}

.slider .glide__bullets {
    bottom: 0em;
}
.slider .glide__bullet {
    width: 12px;
    height: 12px;
    background-color: $c--white;
}

.slider .glide__bullet--active {
    background-color: $c--brand-primary;
}


.products-slider .glide__bullets {
    bottom: 0em;
    @include mq($from: phone-wide) {
        // display: none;
    }
}

.products-slide__inner {
    background-color: $c--grey-theme;
    padding: 10px 10px 0px 10px;
    // min-height: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;


    &:hover,
    &:focus {
        -webkit-box-shadow: -1px 1px 12px 2px rgba(61,76,61,1);
        -moz-box-shadow: -1px 1px 12px 2px rgba(61,76,61,1);
        box-shadow: -1px 1px 12px 2px rgba(61,76,61,1);
    }

    @include mq($from: tablet) {
        padding: 15px;
    }
}

.products-slide__link {
    display: block;
}

.products-slide__info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.products-slider .glide__track {
    @include mq($to: tablet-wide) {
        overflow: initial;
    }
}

.products-slider .glide__slides {
    padding: 15px;
    margin-left: -15px;
}

.products-slider .glide__slide {
    height: auto;
    flex-grow: 1;

    @include mq($from: desktop) {
        padding: 7.5px;
    }
}

.products-slider .glide__bullet--active {
    background-color: $c--brand-tertiary;
}


.products-slide__info {

    padding: $spacing-unit--x-small;

    @include mq($from: tablet) {
        padding: $spacing-unit $spacing-unit--small;
    }


    h5 {
        color: $c--brand-primary;
    }
}

.products-slider__title {
    color: $c--brand-tertiary;
    text-align: center;

    &--primary {
        color: $c--brand-primary;
    }
}

.products-slide__text {
    font-family: $opensans-regular;
    color: $c--black;
    line-height: 1.5;

    span {
        display: block;
        font-family: $opensans-bold;
        font-size: rem(15px);
        color: $c--brand-tertiary;

        @include mq($from: tablet) {
            font-size: rem(18px);
        }
    }
}