/*

	Font face

	example:

	@include font-face('Clarendon', 'clarendon-webfont');
	@include font-face('Clarendon', 'clarendon-webfont', $w--bold, italic);

*/

@mixin font-face($name, $filename, $svgfontid, $weight: normal, $style: normal) {
	@font-face {
		font-family: $name;
		src: url('../static/fonts/#{$filename}.eot');
		src: url('../static/fonts/#{$filename}.eot?#iefix') format('embedded-opentype'),
		url('../static/fonts/#{$filename}.woff') format('woff'),
		url('../static/fonts/#{$filename}.ttf') format('truetype'),
		url('../static/fonts/#{$filename}.svg##{$svgfontid}') format('svg');
		font-style: $style;
		font-weight: $weight;
	}

	@media screen and (-webkit-min-device-pixel-ratio: 0) {
		@font-face {
			font-family: $name;
			src: url('../static/fonts/#{$filename}.svg##{$svgfontid}') format('svg');
		}
	}
}

@include font-face('Amaranth-Regular', 'Amaranth-Regular', $w--normal, normal);
@include font-face('Amaranth-Italic', 'Amaranth-Italic', $w--normal, italic);
@include font-face('Amaranth-Bold', 'Amaranth-Bold', $w--bold, normal);
@include font-face('Amaranth-BoldItalic', 'Amaranth-BoldItalic', $w--bold, italic);
@include font-face('OpenSans-Light', 'OpenSans-Light', $w--light, normal);
@include font-face('OpenSans-Regular', 'OpenSans-Regular', $w--normal, normal);
@include font-face('OpenSans-Bold', 'OpenSans-Bold', $w--bold, normal);


/*

	Font families

*/

/*@mixin font-roboto($weight: false, $size: false, $lh: false, $colour: false) {
	font-family: 'Roboto', 'Comic Sans MS', Arial, sans-serif;

	@if $weight { font-weight: $weight; }
	@if $size { font-size: $size; }
	@if $lh { line-height: $lh; }
	@if $colour { color: $colour; }
}*/



/*--------------------------------

	Font face

	example:

	@include font-face('Clarendon', 'clarendon-webfont');
	@include font-face('Clarendon', 'clarendon-webfont', $w-bold, italic);

*/