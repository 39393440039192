/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/*---

Clickable buttons:

```
<div class="button-wrapper">
	<button class="button">Button</button>
</div>

<div class="button-wrapper">
	<a href="#0" class="button">Button (link)</a>
</div>
```

Alternate styles using `secondary`, `positive` and `negative` modifiers:

```
<div class="button-wrapper">
	<button class="button button--secondary">Button: Secondary</button>
</div>

<div class="button-wrapper">
	<button class="button button--positive">Button: Positive</button>
</div>

<div class="button-wrapper">
	<button class="button button--negative">Button: Negative</button>
</div>
```

And sized using `large` and `small`:

```
<div class="button-wrapper">
	<button class="button button--large">Button: Large</button>
</div>

<div class="button-wrapper">
	<button class="button button--small">Button: Small</button>
</div>
```

Other states:

```
<div class="button-wrapper">
	<button class="button" disabled>Button: Disabled</button>
</div>

<div class="button-wrapper">
	<button>Unstyled button</button>
</div>
```

*/


/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.button {
	position: relative;
	display: inline-block;
	/* [1] */
	vertical-align: middle;
	/* [2] */
	font: inherit;
	/* [3] */
	text-align: center;
	/* [4] */
	margin: 0;
	/* [4] */
	cursor: pointer;
	/* [5] */
	padding: rem(10px) rem(20px);
	background-color: $c--brand-primary;
	color: $c--brand-primary;
   	transition: all .5s cubic-bezier(.23,1,.32,1);
	font-size: $p;
	font-weight: $w--normal;
	text-decoration: none;
	-webkit-tap-highlight-color: rgba($c--white, 0);
	@include border-radius(10px 0px 10px 10px);
	font-weight: bold;
	z-index: 1;

	&:focus {
		[data-whatinput='keyboard'] & {
			box-shadow: 0px 0px 3px $c--blue, 0px 0px 6px $c--blue, 0px 0px 9px $c--blue;
		}
	}

	&[disabled] {

		&,
		&:focus {
			background-color: $c--grey-light;
			color: mix($c--grey-light, $c--black, 60%);
		}
	}

	&:after  {
	    content: "";
	    display: block;
	    position: absolute;
	    width: 100%;
	    position: absolute;
	    transform: scaleX(0);
	    top: 0;
	    bottom: 0;
	    left: 0;
	    z-index: -1;
	    transform-origin: 100% 50%;
	    transition: transform .5s cubic-bezier(.23,1,.32,1);
	    @include border-radius(10px 0px 10px 10px);
	}

	&:hover:after {
		transform: scaleX(1);
		transform-origin: 0 50%;
		border: 2px solid transparent;
	}

	// colours

	&--primary {
		color: $c--white;
		border: 2px solid transparent;

		&:hover,
		&:focus {
			background-color: $c--white;
			color: $c--brand-primary;
			border: 2px solid $c--brand-primary;
		}

		&:hover:after {
			transform: scaleX(1);
			background-color: $c--white;
			transform-origin: 0 50%;
		}
	}

	&--secondary {
		color: $c--brand-primary;
		border: 2px solid transparent;
		background-color: $c--grey-theme;

		&:hover,
		&:focus {
			background-color: $c--grey-theme;
			color: $c--brand-primary;
		}

		&:hover:after {
			transform: scaleX(1);
			background-color: $c--white;
			transform-origin: 0 50%;
			border: 2px solid $c--white;
		}
	}

	&--tertiary {
		color: $c--white;
		border: 2px solid transparent;

		&:hover,
		&:focus {
			background-color: $c--white;
			color: $c--brand-secondary;
			border: 2px solid $c--brand-secondary;
		}

		&:hover:after {
			transform: scaleX(1);
			background-color: $c--white;
			transform-origin: 0 50%;
		}
	}

	&--positive {
		background-color: $c--positive;
	}

	&--negative {
		background-color: $c--negative;
	}



	// sizes
	&--large {
		padding: rem(22px) rem(30px);

		@include mq(tablet) {
			padding: rem(25px) rem(40px);
			font-size: $p--medium;
		}
	}

	&--small {
		padding: rem(12px);
		font-size: $p--small;
	}

}

/* Ghost buttons
   ========================================================================== */
/**
 * Ghost buttons have see-through backgrounds and are bordered.
 */

 $btn-ghost-border-width: rem(2px);

 .button--ghost {
   border: $btn-ghost-border-width solid currentColor;
   padding: ($spacing-unit--small - $btn-ghost-border-width) ($spacing-unit - $btn-ghost-border-width);

   &,
   &:hover,
   &:active,
   &:focus {
	 background: none;
   }

   &.button--small {
	 padding: ($spacing-unit--x-small - $btn-ghost-border-width) ($spacing-unit--small - $btn-ghost-border-width);
   }

   &.button--large {
	 padding: ($spacing-unit - $btn-ghost-border-width) ($spacing-unit--large - $btn-ghost-border-width);
   }

   &.button--primary {
	 color: $c--brand-primary;

	 &:hover,
	 &:focus {
	   color: $c--brand-primary;
	 }

   }

   &.button--secondary {
		color: $c--brand-secondary;

		&:hover,
		&:focus {
		   color: $c--brand-secondary;
		}
   }

   &.button--tertiary {
		color: $c--brand-tertiary;
		background: rgba($color: $c--white, $alpha: 0.5);


		&:hover,
	 	&:focus {
			color: $c--white;
			background: $c--brand-tertiary;
			border: 2px solid transparent;
		}
		&:hover:after {
			transform: scaleX(1);
			background-color: $c--brand-tertiary;
			transform-origin: 0 50%;
			border: 2px solid $c--brand-tertiary;
		}
   }

 }