/* ==========================================================================
   #SERVICE PAGE
   ========================================================================== */

   .service-block {

   		height: 100%;
   		display: flex;
		flex-direction: column;

		@include push--ends($spacing-unit);

		@include mq($from: tablet) {
			@include push--ends($spacing-unit--medium);
		}

		.placeholder {
			margin-bottom: $spacing-unit;
		}

		&__copy {
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		&__copy h4 {
			color: $c--brand-secondary;
			letter-spacing: 0.03em;
		}

		&__copy p {
			color: $c--black;
			margin-bottom: $spacing-unit;
			line-height: 1.6;
			font-size: rem(18px);
			min-height: rem(85px);
		}

		&__copy a {
			align-self: flex-end;

		}
   }