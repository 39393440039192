/* ========================================================================
  #HEADROOM plugin custom styles
======================================================================== */

$site-header-height: 56px;


.headroom {
    box-shadow: none;
    background-color: transparent;

    &--unpinned,
    &--not-top {

        &.site-header__placeholder {
            position: fixed;
            top: 0;
            height: $site-header-height;
            animation: slideUp;
            box-shadow: 0 calc(8px / 2) 8px rgba(0, 0, 0, .05);
            background-color: $c--white;
            transition: all;
            max-width: none;
            width: 100vw;

        }

        & .site-nav__logo {
            width: rem(100px);
            height: rem(40px);
        }

        & .site-nav__links {
            margin-top: 8px;
            padding-bottom: 8px;
        }

        & .site-nav__item a {
            @include mq($from: tablet) {
                font-size: rem(16px);
                color: $c--brand-primary;
            }
        }

        .site-nav__item--lang a:first-child:after {
            background-color: $c--brand-primary ;
        }
    }

}