$browser-default-font-size: 16px !default;

/*
	REMs
*/

$rems-on: true !default;
$rems-base-font-size: ($browser-default-font-size / 100) * $html-font-size;

@function rem($size, $reverse: false) {
	$unitless: strip-unit($size);
	$unitless-base: strip-unit(if(is-percentage($html-font-size), $rems-base-font-size, $html-font-size));

	@if $reverse==false {
		@if $rems-on {
			@if str-index(#{$size}, 'px') {
				@return $unitless / $unitless-base * 1rem;
			}

			@else if str-index(#{$size}, 'rem') {
				@return $size;
			}
		}

		@else {
			@if str-index(#{$size}, 'px') {
				@return $size;
			}

			@else if str-index(#{$size}, 'rem') {
				@return $unitless * $unitless-base * 1px;
			}
		}
	}
	@else {
		@return $unitless * $unitless-base * 1px;
	}
}

/*

	EMs calculator

*/

$em-media-queries: true !default;

@function em($size, $base: $browser-default-font-size) {

	$size: if(str-index(#{$size}, 'rem'), rem($size, true), $size);
	$base: if(unit($base)==rem, rem($base, true), $base);

	$unitless: strip-unit($size);
	$unitless-base: strip-unit($base);

	@if $em-media-queries {

		@return $unitless / $unitless-base * 1em;

	}

	@else {

		@return $size;

	}

}


/*

	Grids

*/

@function grid-calc($index, $total: $grid-column-count) {
	@if $index==0 {
		@return 0%;
	}

	@else {
		@return 100%/($total/$index);
	}
}


/*

	Z-indexing

	use:

		instead of guessing or adding random z-indexes throughout the project (e.g. 100000, 999999, etc.), call the z-index function to generate a z-index from a stacked list of classes

	prerequisits:

		$z-indexes list must exist in variables file

	example:

		.box {
			z-index: z(box);
		}

*/

@function z($name) {

	@if index($z-indexes, $name) {

		@return (length($z-indexes) - index($z-indexes, $name))+1;

	}

	@else {

		@warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';

		@return null;

	}
}


/*

	Photoshop letter spacing

	use:

		for simple conversion between Photoshop letter-spacing to ems

	prerequisits:

		$ls list must exist in variables file

	example:

		.awesome-heading {
			letter-spacing: ls('awesome-heading');
		}

		could generate (if "awesome-heading") is 2nd in the list:

		.awesome-heading {
			letter-spacing: -0.01em;
		}

*/

@function ls($name) {
	$value: map_get($ls, $name);
	@return $value / 1000 * 1em;
}

@function ls-basic($value) {
	@return $value / 1000 * 1em;
}


/*

	Map deep get

	Get values from anywhere in a variable list

	http://css-tricks.com/snippets/sass/deep-getset-maps/

*/

@function map-deep-get($map, $keys...) {
	@each $key in $keys {
		$map: map-get($map, $key);
	}

	@return $map;
}


/*

	Strip unit

*/

@function strip-unit($number) {
	@if type-of($number)=='number'and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}


/*

	Very simple number functions

*/

@function h($num) {
	@return $num / 2;
}

@function n($num) {
	@return 0 - $num;
}

@function hn($num) {
	@return n(h($num));
}


/*

	Photoshop letter spacing

	use:

		get the value of a particular breakpoint

	example:

		.box {
			width: breakpoint('phone-wide');
		}

		would generate:

		.box {
			width: 480px;
		}

*/

@function breakpoint($name, $rems: false) {
	@if $rems {
		@return map_get($breakpoints--rem, $name);
	}

	@else {
		@return map_get($breakpoints, $name);
	}
}


/*

	Colour map lookup, retrieving base value by default

*/

@function c($colour, $value: 'base') {

	@if map-has-key($colours, $colour) {

		$colour: map-get($colours, $colour);
		$colourVal: map-get($colour, $value);
		@return $colourVal;

	}

	@else {

		@warn 'Colour name #{$colour} not found in $colours.';

	}

}


/*

	SVG inliner

*/

@function svg-url($svg) {

	//
	//  Add missing namespace
	//
	@if not str-index($svg, xmlns) {
		$svg: str-replace($svg, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
	}

	//
	//  Chunk up string in order to avoid
	//  "stack level too deep" error
	//
	$encoded:'';
	$slice: 2000;
	$index: 0;
	$loops: ceil(str-length($svg)/$slice);

	@for $i from 1 through $loops {
		$chunk: str-slice($svg, $index, $index + $slice - 1);
		//
		//   Encode
		//
		$chunk: str-replace($chunk, '"', '\'');
		$chunk: str-replace($chunk, '%', '%25');
		$chunk: str-replace($chunk, '#', '%23');
		$chunk: str-replace($chunk, '{', '%7B');
		$chunk: str-replace($chunk, '}', '%7D');
		$chunk: str-replace($chunk, '<', '%3C');
		$chunk: str-replace($chunk, '>', '%3E');

		//
		//    The maybe list
		//
		//    Keep size and compile time down
		//    ... only add on documented fail
		//
		//  $chunk: str-replace($chunk, '&', '%26');
		//  $chunk: str-replace($chunk, '|', '%7C');
		//  $chunk: str-replace($chunk, '[', '%5B');
		//  $chunk: str-replace($chunk, ']', '%5D');
		//  $chunk: str-replace($chunk, '^', '%5E');
		//  $chunk: str-replace($chunk, '`', '%60');
		//  $chunk: str-replace($chunk, ';', '%3B');
		//  $chunk: str-replace($chunk, '?', '%3F');
		//  $chunk: str-replace($chunk, ':', '%3A');
		//  $chunk: str-replace($chunk, '@', '%40');
		//  $chunk: str-replace($chunk, '=', '%3D');

		$encoded: #{$encoded}#{$chunk};
		$index: $index + $slice;
	}

	@return url("data:image/svg+xml,#{$encoded}");
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
	@return if($index,
	str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace),
	$string);
}


/// Gets a palette value.
/// @param {string} $keys Key(s).
/// @return {string} Value.
@function _palette($keys...) {
	@return val($palette, $keys...);
}