/*---

Self-contained content, with support for a caption:

```
<div style="max-width: 35em">
	<figure>
		<div class="placeholder">
			<img src="//placehold.it/1600x900" alt="Always specify alt text">
		</div>

		<figcaption>Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus, facilis, sed non dolore excepturi beatae veritatis corporis cumque tempora quo harum voluptatem et, suscipit similique nisi necessitatibus perferendis dolores consectetur.</figcaption>
	</figure>
</div>
```

*/

figure {
	img {
		@include push--auto;
	}
}

figcaption {
	margin: $rel-spacing-unit--small auto 0;
	max-width: 30em;
	font-style: italic;
	text-align: center;
}
