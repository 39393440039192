/* ==========================================================================
   # MODULE: LAYOUT
   ========================================================================== */

// layout-push

@mixin layout-push($spacing, $modifier) {
    $modifier: if($modifier and $modifier != 'base', --#{$modifier}, '');

    .layout-push#{$modifier} {
        margin-top: $spacing;
    }
}

    $spacing-list: (
        small:  $spacing-unit--small,
        medium: $spacing-unit--medium,
        large:  $spacing-unit--large,
        x-large: $spacing-unit--x-large,
    );


@each $modifier, $spacing in $spacing-list {
	@include layout-push($spacing,$modifier);
}


//layout-width

    .layout-width {

        &--70 {
            width: 100%;    
            
            @include mq($from: tablet-small) {
                @include push--sides($spacing-unit--small);
                width: 85%;
                @include push--auto();
            }

            @include mq($from: tablet-wide) {
                width: 80%
            }

            @include mq($from: desktop) {
                width: 70%;
            }
        }

        &--60 {
            width: 90%;
            @include push--sides($spacing-unit--small);

            @include mq($from: tablet-small) {
                width: 75%;
                @include push--auto();
            }

            @include mq($from: tablet-wide) {
                width: 70%
            }

            @include mq($from: desktop) {
                width: 60%;
            }
        }

    }
