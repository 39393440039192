.is-hidden {
	@include hidden;
}

.vh {
	@include vh;
}

.cf {
	@include cf;
}

.push--auto {
	@include push--auto;
}


/*
	Spacing
*/

.layout-padding {
	padding-top: $spacing-unit--small;
	padding-bottom: $spacing-unit--small;

	@include mq(tablet) {
		padding-top: $spacing-unit;
		padding-bottom: $spacing-unit;
	}

	&--small {
		padding-top: $spacing-unit--x-small;
		padding-bottom: $spacing-unit--x-small;

		@include mq(tablet) {
			padding-top: $spacing-unit--small;
			padding-bottom: $spacing-unit--small;
		}
	}

	&--medium {
		padding-top: $spacing-unit;
		padding-bottom: $spacing-unit;

		@include mq(tablet) {
			padding-top: $spacing-unit--medium;
			padding-bottom: $spacing-unit--medium;
		}
	}

	&--large {
		padding-top: $spacing-unit--medium;
		padding-bottom: $spacing-unit--medium;

		@include mq(tablet) {
			padding-top: $spacing-unit--large;
			padding-bottom: $spacing-unit--large;
		}
	}
}

.push-top {
	margin-top: $spacing-unit--small;

	@include mq(tablet) {
		margin-top: $spacing-unit;
	}

	&--small {
		margin-top: $spacing-unit--x-small;

		@include mq(tablet) {
			margin-top: $spacing-unit--small;
		}
	}

	&--medium {
		margin-top: $spacing-unit;

		@include mq(tablet) {
			margin-top: $spacing-unit--medium;
		}
	}

	&--large {
		margin-top: $spacing-unit--medium;

		@include mq(tablet) {
			margin-top: $spacing-unit--large;
		}
	}
}

/*

	Typography

*/

// Font weights
.font-weight {
	&--normal {
		font-weight: $w--normal;
	}

	&--bold {
		font-weight: $w--bold;
	}
}

// Font styles
.font-style {
	&--italic {
		font-style: italic;
	}
}

// Text alignment
.text-align {
	&--left {
		text-align: left;
	}

	&--center {
		text-align: center;
	}

	&--right {
		text-align: right;
	}

	@each $name, $min-width in $breakpoints {
		@include mq($name) {
			&--#{$name}-left {
				text-align: left;
			}

			&--#{$name}-center {
				text-align: center;
			}

			&--#{$name}-right {
				text-align: right;
			}
		}
	}
}


.is-branded {

	&--primary {
		color: $c--brand-primary;
	}

	&--secondary {
		color: $c--brand-secondary;
	}

	&--tertiary {
		color: $c--brand-tertiary;
	}
}

$widths: (50,60,70,80);

@each $size in $widths {
	.layout-width {
		@include mq($from: desktop) {
			&--#{$size} {
				width: calc((#{$size} * 100%)/100);
			}
		}
	}
}

.bg-grey-light {
	background-color: $c--grey-theme;
}

.rounded {
	@include border-radius(10px 0px 10px 10px);
}
